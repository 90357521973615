






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import moment from "moment";
import * as _ from "lodash";

export default Vue.extend({
    methods: {
        isSameMonth(timestamp1, timestamp2) {
            return moment(timestamp1).isSame(moment(timestamp2), 'month');
        },
        hasOverlappingRanges(ranges) {
            return _.some(ranges, (range1, index1) =>
                _.some(ranges, (range2, index2) =>
                    index1 !== index2 && range2.minValue < range1.maxValue && range2.minValue >= range1.minValue
                )
            );
        },
        validatePromotion(v) {
            const {startDate, endDate, repeat_every_month, promotionSettingItems} = v;
            if (startDate && endDate) {
                if (startDate > endDate) {
                    throw new Error("Start date must be less than end date");
                }
            }

            if (repeat_every_month) {
                if (!this.isSameMonth(startDate, endDate)) {
                    throw new Error("Start date and end date must be in the same month");
                }
            }

            if (promotionSettingItems?.length && this.hasOverlappingRanges(promotionSettingItems)) {
                throw new Error("Promotion setting items must not have overlapping values");
            }
        },
    },
    data() {
        return {
            tableOptions: {
                attrs: {
                    "item-key": "_id",
                    "sort-by": "licensePlate",
                    "sort-desc": false,
                },
                content: {
                    name: "Promotions",
                    search: {
                        ext: {
                            hidden: true,
                        },
                    },
                    filters: {
                        filters: {
                            name: {
                                type: "text",
                                attrs: {
                                    label: "Name",
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            startDate: {
                                type: "XDateTimePicker",
                                rules: {
                                    greater_than_or_equal_to: {
                                        attrs: {
                                            label: "Start Time (From)",
                                        },
                                    },
                                    less_than_or_equal_to: {
                                        attrs: {
                                            label: "Start Time (To)",
                                        },
                                    },
                                },
                            },
                            endDate: {
                                type: "XDateTimePicker",
                                rules: {
                                    greater_than_or_equal_to: {
                                        attrs: {
                                            label: "End Time (From)",
                                        },
                                    },
                                    less_than_or_equal_to: {
                                        attrs: {
                                            label: "End Time (To)",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    displayFields: {
                        name: {
                            text: "Name",
                            options: {
                            },
                        },
                        type: {
                            text: "Type",
                            options: {
                                transform: (value) => {
                                    switch (value) {
                                        case "percent":
                                            return "Percent";
                                        default:
                                            return value;
                                    }
                                },
                                label: true,
                            },
                        },
                        startDate: {
                            text: "Start Date",
                            options: {
                                transform: (data, {repeatEveryMonth, _startDate}) => {
                                    if (repeatEveryMonth) {
                                        return _startDate
                                    }
                                    return  new Date(data).toLocaleDateString("en-GB");
                                },
                            },
                        },
                        endDate: {
                            text: "End Date",
                            options: {
                                transform: (data, {repeatEveryMonth, _endDate}) => {
                                    if (repeatEveryMonth) {
                                        return _endDate
                                    }
                                    return  new Date(data).toLocaleDateString("en-GB");
                                },
                            },
                        },
                        repeatEveryMonth: {
                            text: "Repeat",
                            options: {
                                label(value) {
                                    return (value && "Yes") || "No";
                                },
                                attrs(value) {
                                    return {
                                        color: value ? "green" : "red",
                                    };
                                },
                            },
                        },
                        promotionSettingItems: {
                            text: "Promotion Value",
                            options: {
                                transform: (promotionSettingItems) => {
                                    return _.map(promotionSettingItems, ({promotionValue}) => {
                                        return `${promotionValue}%`;
                                    }).join(", ");
                                },
                            },
                        },
                        active: {
                            text: "Active",
                            options: {
                                label(value) {
                                    return (value && "True") || "False";
                                },
                                attrs(value) {
                                    return {
                                        color: value ? "green" : "red",
                                    };
                                },
                            },
                        },
                        createdTime: {
                            text: "Created Time",
                            options: {
                                transform: (data) => {
                                    return new Date(data).toLocaleDateString("en-GB");
                                },
                            },
                        },
                        action: {
                            text: "Actions",
                        },
                    },
                    topActionButtons: {
                        insert: userManager.checkRole(["systemAdmin", "admin"]) && {
                            target: {
                                dialog: {
                                    attrs: {
                                        width: "800px",
                                    },
                                },
                            },
                        },
                    },
                    template: {
                        itemActionButtons: {
                            edit: userManager.checkRole(["systemAdmin", "admin"]) && {
                                target: {
                                    dialog: {
                                        attrs: {
                                        width: "800px",
                                    },
                                        ext: {
                                            subTitleMaker: "name",
                                        },
                                    },
                                },
                            },
                            delete: userManager.checkRole(["systemAdmin", "admin"]) && {},
                        },
                    },
                },
                ext: {
                    dataProvider: {
                        async findAll(options) {
                            const { items, count } = await coreApiClient.call("promotions", "findAll", options);
                            _.map(items, (item) => {
                                if (item.repeatEveryMonth) {
                                    item._startDate = item.startDate;
                                    item._endDate =item.endDate
                                    item.startDate = moment().date(item.startDate).toDate().getTime();
                                    item.endDate = moment().date(item.endDate).toDate().getTime();
                                }
                            });
                            return [items, count];
                        },
                        async insert(item) {
                            if (item.repeatEveryMonth) {
                                item.startDate = moment(item.startDate).date();
                                item.endDate = moment(item.endDate).date();
                            }
                            return await coreApiClient.call("promotions", "create", undefined, item);
                        },
                        async update(item) {
                            if (item.repeatEveryMonth) {
                                item.startDate = moment(item.startDate).date();
                                item.endDate = moment(item.endDate).date();
                            }
                            return await coreApiClient.call(
                                "promotions",
                                "update",
                                {
                                    id: item._id,
                                },
                                {
                                    data: item,
                                }
                            );
                        },
                        async delete(item) {
                            return await coreApiClient.call("promotions", "delete", {
                                id: item._id,
                            });
                        },
                    },
                    default: {
                        defaultInputs: {
                            name: {
                                type: "text",
                                attrs: {
                                    label: "Name",
                                    required: true,
                                },
                            },
                            type: {
                                type: "select",
                                attrs: {
                                    label: "Type",
                                    required: true,
                                    items: [
                                        {
                                            text: "Percent",
                                            value: "percent",
                                        },
                                    ],
                                },
                            },
                            startDate: {
                                type: "XDateTimePicker",
                                attrs: {
                                    label: "Start Date",
                                    required: true,
                                },
                            },
                            endDate: {
                                type: "XDateTimePicker",
                                attrs: {
                                    label: "End Date",
                                    required: true,
                                },
                            },
                            active: {
                                type: "boolean",
                                attrs: {
                                    label: "Active",
                                },
                            },
                            repeatEveryMonth: {
                                type: "boolean",
                                attrs: {
                                    label: "Repeat Every Month",
                                },
                            },
                            promotionSettingItems: {
                                type: "XArrayInput",
                                attrs: {
                                    label: "Promotions",
                                    required: true,
                                    xOptions: {
                                        content: {
                                            itemLabel: (_, index) => {
                                                return `Item ${+index + 1}`;
                                            },
                                            itemProperties: {
                                                minValue: {
                                                    type: "number",
                                                    attrs: {
                                                        label: "From VND",
                                                        required: true,
                                                        rules: [
                                                            (v) => (v > 0) || "Money must be greater than 0 VND",
                                                        ],
                                                    },
                                                },
                                                maxValue: {
                                                    type: "number",
                                                    attrs: {
                                                        label: "To VND",
                                                        required: true,
                                                        rules: [
                                                            (v) => (v > 0) || "Money must be greater than 0 VND",
                                                        ],
                                                    },
                                                },
                                                promotionValue: {
                                                    type: "number",
                                                    attrs: {
                                                        label: "Promotion %",
                                                        required: true,
                                                        rules: [
                                                            (v) => {
                                                                return (v >= 0 && v <= 100) || "Promotion must be between 0 and 100.";
                                                            },
                                                        ],
                                                    },
                                                },
                                            },
                                            template: {
                                                formOptions: {
                                                    content: {
                                                        colLength: 4,
                                                        rules: [
                                                            (v) => {
                                                                if (v.minValue >= v.maxValue) {
                                                                    throw new Error("Min value must be less than max value");
                                                                }
                                                            },
                                                        ],
                                                    }
                                                },
                                            }
                                        },
                                    },
                                },
                            },
                        },
                        insertForm: {
                            content: {
                                fieldNames: [
                                    "name",
                                    "type",
                                    "startDate",
                                    "endDate",
                                    "active",
                                    "repeatEveryMonth",
                                    "promotionSettingItems",
                                ],
                                inputLevelValid: true,
                                rules: [
                                    (v) => {
                                        return this.validatePromotion(v);
                                    },
                                ],
                            },
                        },
                        editForm: userManager.checkRole(["systemAdmin", "admin"]) && {
                            content: {
                                fieldNames: ["name",
                                    "type",
                                    "startDate",
                                    "endDate",
                                    "active",
                                    "repeatEveryMonth",
                                    "promotionSettingItems",
                                ],
                                rules: [
                                    (v) => {
                                        return this.validatePromotion(v);
                                    },
                                ],
                            },
                        },
                        delete: {
                            confirmDisplayField: "name",
                        },
                    },
                },
            },
        };
    },
});

